.carousel {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  width: 100%;

  @include mediaQuery(max, $mdDesktop) {
    max-width: 915px;
  }

  @include mediaQuery(max, 1023px) {
    max-width: 100%;
  }

  @include mediaQuery(max, 805px) {
    margin-left: 0;
  }
}

.carouselArrow {
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  z-index: 1;

  @include mediaQuery(max, 1023px) {
    display: none;
  }
}

.arrowleft {
  left: -52px;

  @include mediaQuery(max, 1000px) {
    left: -20px;
  }

  @include mediaQuery(max, 900px) {
    left: 15px;
  }

  img {
    margin-right: 2px;
  }
}

.arrowright {
  right: -35px;

  img {
    margin-left: 2px;
  }
}

.carousel-track {
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include mediaQuery(max, $mdDesktop) {
    max-width: 915px;
  }

  @include mediaQuery(max, 1023px) {
    height: 295px;
    max-width: 100%;
    overflow-x: scroll;
    padding-left: 3rem;
  }

  @include mediaQuery(max, $mobile) {
    padding-left: 1rem;
  }
}

.slider-wrapper {
  display: flex;
  height: 100%;
  justify-content: space-between;
  position: absolute;
  transition: $carouselTransition;
  width: 100%;

  @include mediaQuery(max, 835px) {
    position: relative;
  }
}
