@import './styles/mixins';
@import './styles/variables';
@import './styles/global';
@import './styles/typography';
@import './styles/helpers';
@import './styles/forms';
@import './styles/carousel';
@import './styles/algolia';
@import './styles/google';
@import './styles/select';
@import './styles/modal';
@import './styles/phone-input';
@import './styles/calendar';
@import './styles/bulma';
@import './styles/atcb';

// RangeSlider styles

div {
  .MuiSlider-root {
    color: #1ab2c5;
    height: 6px;
    box-sizing: border-box;
  }

  .MuiSlider-thumb {
    width: 0;
    padding: 12px;
    margin-top: -8px;
    margin-left: -12px;
    box-shadow: #ebebeb 0 2px 2px;
    &:focus,
    &:hover {
      box-shadow: #ccc 0 2px 3px 1px;
    }
  }

  .MuiSlider-track {
    height: 6px;
    width: 90%;
  }

  .MuiSlider-rail {
    color: #d8d8d8;
    opacity: 1;
    height: 6px;
    width: 90%;
  }
}

// Toast overrides
:root {
  --toastify-font-family: $font-primary;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeOut {
  animation: fadeOut 0.1s ease-out both;
}

.Toastify__toast.toast {
  position: relative;
  min-height: 68px;
  max-height: 800px;
  overflow: hidden;
  cursor: pointer;
  direction: ltr;
  color: $saldova;
  font-family: $font-primary;
  font-size: 14px;
  line-height: 17.07px;
  font-weight: 600;
  box-shadow: $toastBoxShadow;
  border-radius: 5px;
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  svg[title='close'] {
    height: 13px;
    width: 13px;
    flex-shrink: 0;
  }

  @include mediaQuery(max, $mobile) {
    margin: 0 1.75rem 0.5rem;
  }
}

.Toastify__toast-body.toastBody {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 0px;
}
