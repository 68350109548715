.modalWrapper {
  background-color: rgba(255, 255, 255, 1);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}

.fullWhite {
  background-color: $white;
}

.modal {
  height: calc(100% - 75px);
}

.modalTop {
  align-items: center;
  background-color: $white;
  display: flex;
  justify-content: flex-end;
  left: 0;
  padding: 10px 24px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;

  @include mediaQuery(max, $tablet) {
    justify-content: space-between;
    padding: 6px 16px 6px 32px;
  }

  @include mediaQuery(max, 625px) {
    border-bottom: 1px solid $inputBorder;
  }
}

.modalClose {
  background-color: transparent;
  border: none;
  cursor: pointer;
  line-height: 11px;
  padding: 0rem;

  img {
    padding: 1rem;
  }

  &.responsiveCloseIcon {
    padding: 0.9rem;

    svg {
      width: 20px;
      height: 20px;
    }

    @include mediaQuery(max, $tablet) {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.modalBody {
  margin: 71px auto 0;
  max-width: 1280px;
  overflow: auto;
  padding: 0 165px;
  width: 100%;

  @include mediaQuery(max, $smDesktop) {
    padding: 0 80px;
  }

  @include mediaQuery(max, $tablet) {
    padding: 0 32px;
  }

  @include mediaQuery(max, 625px) {
    padding: 0 16px 32px;
  }
}

.desktopHeading {
  font-weight: 400;

  @include mediaQuery(max, 625px) {
    display: none;
  }
}

.mobileHeading {
  display: none;
  font-weight: 400;

  @include mediaQuery(max, 625px) {
    display: block;
    font-size: 18px;
    line-height: 21px;
  }
}
