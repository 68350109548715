@import 'colors';

* {
  box-sizing: border-box;

  &:root {
    font-size: 16px;

    @include mediaQuery(max, $tablet) {
      font-size: 18px;
    }
  }

  // Safari Smooth Scrolling
  @media not all and (min-resolution: 0.001dpcm) {
    @include mediaQuery(max, $tablet) {
      @supports (-webkit-appearance: none) {
        -webkit-overflow-scrolling: touch;
      }
    }
  }
}

html,
body {
  color: $saldova;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
}

button {
  color: $black;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@include mediaQuery(max, $mobile) {
  :not(.showScrollBar)::-webkit-scrollbar {
    display: none;
  }
}

a {
  color: $blue;
  text-decoration: none;
}

input {
  background: white;
}

.payment-iframe {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  border: none;
}

.wp-block-heading[id] {
  scroll-margin-top: 77px;
}
