.ais-SearchBox {
  max-width: 375px;
  width: 100%;

  @include mediaQuery(max, 950px) {
    margin-left: 1rem;
    width: 280px;
  }

  @include mediaQuery(max, $mobile) {
    margin-left: 0;
    max-width: 100%;
    width: 100%;
  }
}

.ais-SearchBox-form {
  height: 100%;
}

.ais-SearchBox-input {
  border-color: $inputBorder;
  border-radius: 4px;
  color: $blue-dark;
  font-family: $font-primary;
  font-size: 1rem;
  height: 100%;
  padding: 0.7rem 2rem;
}

.ais-Hits-list {
  flex-direction: column;
  margin: 0;
}

.ais-Hits-item {
  flex: 0 0 100%;
  margin-left: 0;
  padding: 0;
  width: 100%;
}
