@import '../../styles/styles';

.scmOverlay {
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  left: 0;
  opacity: 1;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 500ms;
  z-index: 9999;

  &:target {
    opacity: 1;
    visibility: visible;
  }
}

.scmPopup {
  background: #fff;
  border-radius: 5px;
  box-sizing: content-box;
  margin: 100px auto;
  max-height: calc(100vh - 140px);
  max-width: 659px;
  min-height: 0;
  padding: 26px 29px 23px 29px;
  position: relative;
  width: calc(100vw - 40px);
  -moz-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

  & > h2 {
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    padding: 0 48px 10px 0;
  }

  @include mediaQuery(max, $mobile) {
    width: auto;
    margin-left: 12px !important;
    margin-right: 12px !important;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-height: calc(100vh - 80px);
  }
}

.scmIcon {
  background: transparent;
  border: none;
  font-size: 30px;
  font-weight: bold;
  margin-left: 20px;
  padding: 0;
  position: absolute;
  right: 30px;
  text-decoration: none;
  top: 20px;
}

.scmContent {
  max-height: calc(100vh - 300px);
  min-height: 0;
  overflow: auto;
  p {
    margin: 0;
  }
  a {
    font-weight: 700;
  }
  h3 {
    font-family: $font-primary;
  }

  @include mediaQuery(max, $mobile) {
    font-size: 14px;
  }
}

.scmButtonContainer {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  @include mediaQuery(max, $mobile) {
    flex-direction: column;

    & > button {
      width: 100%;
      font-size: 16px;
    }

    & > button:nth-child(1) {
      order: 2;
    }

    box-shadow: 3px 3px 5px 0 rgb(10 63 70 / 10%),
      -3px -3px 5px 0 rgb(10 63 70 / 5%);
    position: absolute;
    width: 100%;
    left: 0;
    padding: 22px 29px;
  }
}

.scmCategoryListSection {
  display: flex;
  flex-direction: column;
  margin: 23px 0;

  & > .item {
    border-top: 1px solid #d8d8d8;
    display: flex;
    flex-direction: column;
    padding: 18px 0;
    width: 100%;
    @include mediaQuery(min, $tablet) {
      flex-direction: row;
    }
  }

  & > .item:last-child {
    border-bottom: 1px solid #d8d8d8;
  }

  & > .item > div:first-child {
    display: flex;
    font-size: 12px;
    font-weight: bold;
    gap: 8px;
    line-height: 24px;
    width: 250px;
  }

  & > .item > div:nth-child(2) {
    @include mediaQuery(min, $desktop) {
      width: 65rem;
    }
  }

  & > .item h3 {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    margin: 0;
    padding: 0;
  }

  & > .item p {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    padding: 0 23px 0 0;
    width: 535px;
    @include mediaQuery(max, $mobile) {
      width: auto !important;
    }
  }
}
