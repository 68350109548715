@import './variables';
@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';

@font-face {
  font-family: eicons;
  src: url('/assets/styles/elementor/fonts/eicons.eot?5.3.0');
  src: url('/assets/styles/elementor/fonts/eicons.eot?5.3.0#iefix')
      format('embedded-opentype'),
    url('/assets/styles/elementor/fonts/eicons.woff2?5.3.0') format('woff2'),
    url('/assets/styles/elementor/fonts/eicons.woff?5.3.0') format('woff'),
    url('/assets/styles/elementor/fonts/eicons.ttf?5.3.0') format('truetype'),
    url('/assets/styles/elementor/fonts/eicons.svg?5.3.0#eicon') format('svg');
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: $font-primary;
  font-weight: 400;
  text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-secondary;
  font-weight: 800;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 3.125rem;
  letter-spacing: -0.3px;
  line-height: 3.125rem;

  @include mediaQuery(max, $tablet) {
    font-size: 3rem;
    line-height: 3rem;
  }

  @include mediaQuery(max, $mobile) {
    font-size: 2.25rem;
    line-height: 2.25rem;
  }
}

h2 {
  font-size: 38px;
  line-height: 40px;
}

h3 {
  font-size: 32px;
  line-height: 36px;
}

h4 {
  font-size: 22px;
  line-height: 26px;
}

h5 {
  font-family: $font-primary;
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
}

p:not([class^='elementor-'] > p) {
  font-size: 16px;
  line-height: 27px;
}

.body {
  font-size: 15px;
  line-height: 24px;
}

.listItem {
  align-items: flex-start;
  color: $bodyText;
  display: flex;
  margin: 12px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  img {
    height: 13px;
    width: 14px;
  }

  span {
    display: block;
    font-size: 16px;
    line-height: 24px;
    margin-left: 14px;
    width: calc(100% - 14px);
  }
}
