// InfoWindow wrapper, removing bottom carrot
.gm-style-iw-t:after {
  content: none !important;
}

.gm-style-iw-c {
  padding: 0 !important;
  width: auto;
  background-color: transparent !important;
  box-shadow: 0 10px 20px rgba(9, 63, 69, 0.3) !important;
  border-radius: 0 !important;
  button[title='Close'] {
    display: none !important;
  }
  button.gm-ui-hover-effect {
    display: none !important;
  }
}

.gm-style-iw-d {
  overflow: auto !important;
}
