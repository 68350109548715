@import 'bulma/sass/utilities/_all';
@import 'bulma/sass/elements/image';

.is-3by2-mobile {
  @include mediaQuery(max, 599px) {
    padding-top: 66.6666%;
  }
}

.is-16by9-desktop {
  @include mediaQuery(min, $mobile) {
    padding-top: 56.25%;
  }
}
