@import '../../styles/styles';

.scmContainer {
  bottom: 22px;
  left: 22px;
  position: fixed;
  z-index: 9999;
  &.hideModal {
    display: none;
  }
}

.scmPopup {
  background: #fff;
  border-radius: 5px;
  max-width: 657px;
  padding: 26px 29px 24px 29px;
  position: relative;
  width: calc(100vw - 40px);

  -moz-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

  & > h2 {
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    padding: 0 48px 16px 0;
    @include mediaQuery(max, $mobile) {
      font-size: 18px;
    }
  }
}

.scmIcon {
  border: none;
  background: none;
  padding: 0;
  position: absolute;
  right: 30px;
  text-decoration: none;
  top: 20px;
  margin-left: 20px;
}

.scmContent {
  line-height: 24px;
  max-height: 30%;
  overflow: auto;
  @include mediaQuery(max, $mobile) {
    font-size: 14px;
  }
}

.scmButtonContainer {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 22px;
  @include mediaQuery(max, $mobile) {
    flex-direction: column;

    & > button {
      width: 100%;
      font-size: 16px;
    }

    & > button:nth-child(1) {
      order: 2;
    }
  }
}
