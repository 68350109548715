@import 'react-dates/lib/css/_datepicker.css';
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 620px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;

  & button:enabled:hover {
    background-color: white;
  }
}
.react-calendar__navigation__label {
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: default !important;

  &.button:enabled:hover {
    background-color: white;
  }
}

.react-calendar__navigation__label__divider {
  display: none;
}

.react-calendar__navigation__label__labelText {
  color: #093f46;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  margin: 0;
  padding: 0;
  border-radius: 3px;

  &:not(.react-calendar__navigation__arrow) {
    border: none;
  }
}
.react-calendar__navigation__arrow {
  border: 1px solid #e6e6e6;

  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -9px;
    margin-left: -9px;
  }
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

.react-calendar__month-view__days__day {
  color: #484848;
  margin: -0.5px;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  font-weight: 300;
  font-size: 14px;
  border: 1px solid #d8d8d8;
  border-radius: 0px;
  transition: none;
  position: relative;

  &:focus {
    border: 1px solid #e4e7e7;
    border-radius: 0px;
  }
}

.react-calendar__tile--disabled,
.react-calendar__tile:disabled {
  color: #cacccd;
}

.react-calendar__tile .tooltip-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;

  .__react_component_tooltip {
    max-width: 100px;
    padding-left: 8px;
    padding-right: 8px;

    @include mediaQuery(min, $mobile) {
      max-width: 175px;
    }
  }

  span {
    font-size: 12px;
    font-weight: 400;
  }
}

.react-calendar:not(.react-calendar.react-calendar--booking) {
  .react-calendar__tile {
    &:not(:disabled, &.react-calendar__tile--range):hover {
      background-color: #d8d8d8;

      &:before,
      &:after {
        background-color: transparent;
      }
    }
  }
}

.react-calendar--booking {
  .react-calendar__tile--hoverable:hover {
    background-color: #d8d8d8;

    &:before,
    &:after {
      background-color: transparent;
    }
  }
}

.react-calendar__tile--now {
  background: white;
}

.react-calendar__tile--active {
  background-color: #29b2c4;
  color: white;

  &:enabled:hover,
  &:focus {
    background-color: #176570;
    color: white;
  }
}

.react-calendar--selectRange:not(.react-calendar--booking)
  .react-calendar__tile--hover:not(.react-calendar__tile--rangeStart) {
  background-color: #e6e6e6;
}

.react-calendar__tile--range {
  background-color: #29b2c4;
  color: white;
}

.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
  background-color: #176570;
  color: white;

  &:enabled:hover {
    background-color: #176570;
  }
}

.react-calendar__month-view__weekdays {
  text-transform: none;
  abbr {
    text-decoration: none;
  }
}

.DateRangePicker {
  position: relative;
  display: inline-block;
  height: 100%;
}

.DateRangePickerContainer {
  @include transition(border-color);
  background-color: $white;
  border: 1px solid $inputBorder;
  border-radius: 4px;
  max-height: 50px;
  min-width: 14rem;
  width: 100%;

  &.focused {
    border: 3px solid $inputBorder;
  }

  @include mediaQuery(min, 1170px) {
    max-width: 20.5rem;
  }

  .combinedDatePickerTrigger {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    padding: 0.75rem 0.6rem;
    width: 100%;
    max-height: 45px;
    min-width: max-content;

    svg {
      height: 18px;
      margin-right: 0.25rem;
      width: 18px;
    }

    span {
      font-size: 0.875rem;
      font-weight: 500;
    }

    &.small {
      max-height: 38px;
      span {
        font-size: 14px;
      }
    }
  }

  .DateRangePickerInput_arrow {
    svg {
      left: -0.5rem;
      position: relative;
      top: -1px;
      height: calc(100% + 2px);
    }
  }

  &.small {
    display: flex;
    min-width: 7.5rem;
    width: auto;
    max-height: 47px;

    .DateRangePickerInput_arrow {
      height: 45px;

      svg {
        left: -0.5rem;
        top: -1px;
        width: auto;
        position: relative;
        height: calc(100% + 1px);
      }
    }

    .DateRangePickerInput_calendarIcon {
      position: relative;
      left: 0;
      top: 2px;

      img {
        height: 1rem;
        width: 1rem;
      }
    }

    .DateInput,
    .DateInput_input {
      background-color: transparent;
    }

    .DateInput_input {
      font-size: 16px;
      padding: 9px 2px;
    }
  }
}

.DateRangePicker {
  width: 100%;

  .DateInput {
    position: static;
    vertical-align: auto;
    width: calc(50% - 1rem);

    @include mediaQuery(min, $tablet) {
      width: calc(50% - 2rem);
    }
    @include mediaQuery(min, $desktop) {
      width: 7.5rem;
    }
  }
}

.DateRangePicker_picker {
  z-index: 8;
}

.DayPicker__withBorder {
  box-shadow: $standardBoxShadow;

  @include mediaQuery(max, $tablet) {
    box-shadow: none;
    margin: 0 !important;
    transform: translateY(50%);
    width: 100% !important;
  }
}

.DateRangePicker_picker__portal {
  height: calc(100% - 5rem);
  z-index: 10;
}

.DayPicker_portal__horizontal {
  left: 0;
  top: -60px;
  height: 300px;

  > div > div {
    margin: 0 auto;
  }
}

.DateInput_input {
  border: none;
  color: $casal;
  font-size: 16px;
  font-weight: 600;
  position: static;

  @include mediaQuery(min, $tablet) {
    font-size: 1rem;
  }
  .small & {
    height: 2.5rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  &::placeholder {
    font-weight: 500;
  }
}

.DateRangePickerInput {
  background-color: transparent;
  height: 100%;
  display: block;

  @include mediaQuery(max, $tablet) {
    align-items: center;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    width: 100%;
  }
}

.DateRangePickerInput_calendarIcon {
  padding: 0;
  position: relative;
  top: 2px;
  left: 3px;
}

.DateRangePickerInput_arrow {
  height: 100%;
  line-height: 0;

  img {
    height: 100%;
    left: -0.75rem;
    position: relative;
    top: 0;
  }
}

.DateInput_input__focused {
  border-bottom: none;
}

.DateRangePicker_closeButton {
  display: none;
}

.datePickerHeader {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid rgba(10, 63, 70, 0.1);
  display: none;
  justify-content: space-between;
  left: 0;
  padding: 1rem;

  z-index: 8;

  > div {
    display: flex;
    flex: 1;
    justify-content: center;

    &:first-of-type button {
      margin-right: auto;
    }
    &:nth-of-type(2) {
      min-width: 10rem;
    }
    &:last-of-type button {
      margin-left: auto;
    }
  }

  @include mediaQuery(max, $tablet) {
    display: flex;
  }
}

.header-label {
  color: $blue-dark;
  font-weight: 600;
}

.datePickerFooter {
  background-color: $white;
  border-top: 1px solid rgba(10, 63, 70, 0.1);
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 12px 16px;
  transform: none;
  height: 48px;
  position: static;
  right: 0;
  top: 100%;
  z-index: 11;

  &:not(.mobile) {
    gap: 0 24px;
    justify-content: space-between;
  }

  @include mediaQuery(max, $tablet) {
    transform: translateY(-100%);
    height: auto;
    position: fixed;
  }
}

p:not(.elementor-widget-text-editor > p).datePickerMessage {
  display: flex;
  font-size: 14px;
  gap: 0 6px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  visibility: hidden;

  span {
    font-weight: 700;
  }

  @include mediaQuery(max, $mobile) {
    border-bottom: 1px solid $grey-light;
    padding: 0 32px 18px;
    text-align: center;
    margin-bottom: 16px;
    display: none;

    span {
      margin-right: 4px;
    }
  }
}

p:not(.elementor-widget-text-editor > p).datePickerMessageActive {
  visibility: visible;

  @include mediaQuery(max, $mobile) {
    visibility: visible;
    display: block;
  }
}

.datePickerActions {
  display: flex;
  align-items: center;
  gap: 0 24px;
}

.dateBtn {
  background-color: transparent;
  border: none;
  color: $blue;
  font-size: 0.75rem;
  padding: 0;

  &:disabled {
    cursor: text;
    opacity: 0.5;
  }
}

.clearBtn {
  color: $casal;
}

.applyBtn {
  width: 100%;
}

.calendarDialog {
  position: absolute;
  top: 55px;
  left: 0px;
  width: 619px;
  z-index: 8;
  border-radius: 4px;
  box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;

  &.directionRight {
    left: auto;
    right: 0;
  }

  &.directionCenter {
    left: -50%;
  }
}

.calendarContainer {
  padding: 1rem 1rem 0 1rem;
  background-color: white;
  z-index: 11;
  min-height: 356px;

  @include mediaQuery(max, $tablet) {
    & > div {
      margin: auto;
    }
    position: fixed;
    top: 57px;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 0;
  }
}

.react-calendar {
  .react-calendar__month-view__days {
    justify-content: center;
  }

  .react-calendar__tile {
    border: 1px solid rgba(#d8d8d8, 0.5);
    outline: 1px solid rgba(#d8d8d8, 0.5);
    color: #093f45;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    line-height: 17px;

    @include mediaQuery(min, $tablet) {
      font-size: 14px;
    }

    &:not(.react-calendar__tile--disabled, .react-calendar__tile:disabled, .react-calendar__tile--unreachable) {
      font-weight: 600;
    }

    &--invalid-range.react-calendar__tile--range:not(.react-calendar__tile--hoverable) {
      color: #093f45;
      background-color: #ffffff;
    }

    &--range {
      color: #ffffff;
    }

    &--disabled,
    &:disabled {
      background-color: #ebebeb;
      color: #093f45;

      &.react-calendar__tile--active,
      &.react-calendar__tile--rangeStart,
      &.react-calendar__tile--rangeEnd {
        background-color: #ebebeb;
        color: #093f45;
      }
    }

    &--checkin-only,
    &--checkout-only {
      position: relative;
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ebebeb;
        z-index: 0;
      }

      &.react-calendar__tile--range:not(.react-calendar__tile--invalid-range):before,
      &.react-calendar__tile--range:not(.react-calendar__tile--invalid-range):after {
        background: transparent;
      }

      & > abbr {
        position: relative;
        z-index: 5;
      }
    }

    &--checkin-only:before {
      clip-path: polygon(100% 100%, 0% 100%, 100% 0);
      background-color: #ffffff;
    }

    &--checkin-only:after {
      clip-path: polygon(0 0, 0% 100%, 100% 0);
    }

    &--checkout-only:before {
      clip-path: polygon(0 0, 0% 100%, 100% 0);
      background-color: #ffffff;
    }

    &--checkout-only:after {
      clip-path: polygon(100% 100%, 0% 100%, 100% 0);
    }
  }

  &--booking.react-calendar--selectRange {
    .react-calendar__tile--hover.react-calendar__tile--hoverable:not(.react-calendar__tile--rangeStart) {
      background-color: #e6e6e6;
    }
  }

  &.react-calendar--selectRange {
    .react-calendar__tile--hover.react-calendar__tile--hoverable {
      &.react-calendar__tile--checkin-only,
      &.react-calendar__tile--checkout-only {
        &:before,
        &:after {
          background-color: transparent;
        }
      }
    }
  }
}

.DateRangePickerContainerError {
  border: 2px solid $red;
  transition: none;

  .DateInput {
    vertical-align: baseline;
  }

  input {
    height: 100%;
    margin-bottom: 2px;

    &::placeholder {
      color: $red;
    }
  }

  &.small .DateRangePickerInput_arrow svg {
    top: -2px;
  }

  .DateRangePickerInput_arrow path {
    fill: $red;
  }
}
