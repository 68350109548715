button,
select,
input[type='button'],
input[type='submit'],
input[type='reset'],
input[type='file'] {
  cursor: pointer;
}

button,
input,
select,
textarea {
  @include transition(border-color);
  appearance: none;
  border: 1px solid $input-border;
  border-radius: 4px;
  font-family: $font-primary;
  outline: none;
  padding: 1rem;

  &:focus {
    border-color: $blue;
    outline: none;
  }
}

input,
select,
textarea {
  font-size: 0.8125rem;
  font-weight: 500;

  @include mediaQuery(min, $tablet) {
    font-size: 1rem;
  }
}

label {
  color: $bodyText;
  display: block;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 8px;
}
form {
  input,
  select,
  textarea {
    border: 1px solid $inputBorder;
    border-radius: 4px;
    color: $bodyText;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    max-height: 50px;
    padding: 16px;
    position: relative;
    width: 100%;

    &::placeholder {
      color: #999;
    }

    &:invalid {
      border-color: $glenRose;
    }

    &.error {
      border-color: $glenRose;
    }

    & + span {
      color: $glenRose;
      display: block;
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      margin-top: 6px;
    }
  }
  // Hide intl-tel-input arrows
  .intl-tel-input .flag-container .arrow {
    &.up::after,
    &.down::after {
      content: '';
    }
  }
  .intl-tel-input + span {
    color: $glenRose;
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin-top: 6px;
  }
  span.error {
    color: $glenRose;
    font-weight: 600;
  }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input::-webkit-input-placeholder {
  line-height: normal !important;
}

textarea {
  min-height: 237px;
  resize: none;
}

button {
  font-size: 0.9375rem;
  font-weight: 600;

  @include mediaQuery(min, $tablet) {
    font-size: 1rem;
  }

  &:disabled {
    cursor: text;
  }

  &.btnRed {
    background-color: $red;
    color: $white;
  }
}

select {
  color: $blue-dark;
  margin: 0;
  padding: 1rem;
}

::-webkit-input-placeholder {
  color: $blue-placeholder;
  opacity: 1;
}
:-ms-input-placeholder {
  color: $blue-placeholder;
  opacity: 1;
}
::placeholder {
  color: $blue-placeholder;
  opacity: 1;
}

// Range input
.input-range {
  height: 1rem;
  position: relative;
  width: 100%;
}

.input-range__slider {
  appearance: none;
  background: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 1rem;
}
.input-range__slider:active {
  transform: scale(1.3);
}
.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}
.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none;
}

.input-range__slider-container {
  transition: left 0.3s ease-out;
}

.input-range__label {
  color: #aaaaaa;
  font-family: 'Helvetica Neue', san-serif;
  font-size: 0.8rem;
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: 0;
}

.input-range__label--value {
  position: absolute;
  top: -1.8rem;
}

.input-range__label-container {
  left: -50%;
  position: relative;
}
.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}
.input-range--disabled .input-range__track {
  background: #eeeeee;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: #3f51b5;
}

.formFlex {
  display: flex;
  justify-content: space-between;

  @include mediaQuery(max, 650px) {
    flex-direction: column;
  }
}

.formInputGroup {
  margin-bottom: 24px;
  width: 100%;

  span {
    display: block;
  }

  .star-ratings + span {
    color: $glenRose;
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin-top: 6px;
  }
}

.formInputGroupHalf {
  @extend .formInputGroup;

  max-width: 315px;
  margin-right: 20px;
  min-width: 220px;

  &:last-child {
    margin-right: 0;
  }

  @include mediaQuery(max, 650px) {
    max-width: 100%;
  }
}

.formInputDate {
  border: 1px solid $inputBorder;
  border-radius: 4px;
  color: $bodyText;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  max-height: 50px;
  padding: 2px 0;
  position: relative;
  width: 100%;
}

.formInputDateError {
  @extend .formInputDate;

  border-color: $capeCoral;
}

.formInputErrorMsg {
  color: $capeCoral;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 6px;
}

.checkbox {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  position: relative;

  input {
    display: none;

    &:checked + label:before {
      background-image: url('/assets/icons/icon-checkboxChecked.svg');
    }
    &:disabled + label {
      cursor: text;
      opacity: 0.4;
    }
  }

  label {
    cursor: pointer;
    font-size: 0.875rem;
    display: flex;

    &:before {
      background-image: url('/assets/icons/icon-checkbox.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      cursor: pointer;
      display: inline-block;
      flex: 0 0 0.875rem;
      height: 0.875rem;
      margin-right: 0.5rem;
      position: relative;
      top: 1px;
      width: 0.875rem;
    }

    &.hasError {
      &:before {
        background-image: url('/assets/icons/icon-checkboxError.svg');
      }
    }
  }

  .checkboxErrorMsg {
    color: $glenRose;
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin-top: 6px;
  }
}
