@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#atcb-bgoverlay {
  backdrop-filter: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

#atcb-bgoverlay.atcb-click:hover {
  cursor: default !important;
}

.atcb-list {
  background-color: white !important;
}

.atcb-modal {
  background: white;
  z-index: 2;
  padding: 28px 24px 24px;
  width: 90% !important;
  max-width: 400px !important;
  max-height: 90%;
  overflow-y: auto;
  border-radius: 4px !important;
  box-shadow: 0 2px 4px rgb(0 0 0 / 30%) !important;
  background-color: white !important;
  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
  -ms-animation: fadeIn 0.5s;
  position: relative;
  top: -40px;
}

.atcb-list::before {
  content: 'Select Your Calendar Preference';
  background-image: url("data:image/svg+xml; utf8, <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'><g clip-path='url(%23clip0_1399_20496)'><path d='M18.48 1.716H16.192V1.144C16.192 0.527999 15.708 0.0439987 15.092 0.0439987C14.476 0.0439987 13.992 0.527999 13.992 1.144V1.716H8.008V1.144C8.008 0.527999 7.524 0.0439987 6.908 0.0439987C6.292 0.0439987 5.808 0.527999 5.808 1.144V1.716H3.52C1.584 1.716 0 3.168 0 4.928V18.7C0 20.504 1.584 21.956 3.52 21.956H18.48C20.416 21.956 22 20.504 22 18.744V4.972C22 3.168 20.416 1.716 18.48 1.716ZM3.52 3.872V3.828H5.808V5.016C5.808 5.632 6.292 6.116 6.908 6.116C7.524 6.116 8.008 5.632 8.008 5.016V3.828H13.992V5.016C13.992 5.632 14.476 6.116 15.092 6.116C15.664 6.116 16.148 5.632 16.192 5.016V3.828H18.48C19.14 3.828 19.668 4.312 19.668 4.928V8.624H2.332V4.972C2.332 4.356 2.86 3.872 3.52 3.872ZM18.48 19.844H3.52C2.86 19.844 2.332 19.36 2.332 18.744V10.78H19.668V18.744C19.668 19.36 19.14 19.844 18.48 19.844Z' fill='%23093F45'/></g><defs><clipPath id='clip0_1399_20496'><rect width='22' height='22' fill='white'/></clipPath></defs></svg>");
  padding-top: 40px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 25px;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  font-size: 18px;
  line-height: 24px;
  color: #093f45;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
}

.atcb-list::after {
  content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%23093f46' d='M21.892 19.474l-7.682-7.682 7.682-7.682c.53-.53.53-1.39 0-1.92s-1.39-.53-1.92 0L12.29 9.872 4.608 2.19c-.53-.53-1.39-.53-1.92 0s-.53 1.39 0 1.92l7.682 7.682-7.682 7.682c-.53.53-.53 1.39 0 1.92s1.39.53 1.92 0l7.682-7.682 7.682 7.682c.53.53 1.39.53 1.92 0 .531-.529.531-1.389 0-1.92z'/></svg>");
  position: absolute;
  top: 20px;
  right: 20px;
  width: 12px;
  cursor: pointer;
}

.atcb-list-item {
  font-family: Montserrat, sans-serif;
  font-weight: 600 !important;
  font-size: 18px !important;
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1ab2c5;
  cursor: pointer;
  background: white !important;
  border: 1px #cfcfcf solid !important;
  border-radius: 4px !important;
  height: 50px;
}

.atcb-list-item:hover {
  color: #093f45;
}
