// IntlTelInput
.iti-flag {
  display: none;
}

.selected-flag {
  background-color: $white !important;
  border-radius: 4px;
}

.intl-tel-input .country-list {
  min-height: 325px;
  z-index: 7 !important;
}

.intl-tel-input .selected-flag {
  padding: 0 0 0 6px !important;
}

.iti-mobile .intl-tel-input.iti-container {
  position: 7;
}

// Hack to make country code clickable. See https://github.com/patw0929/react-intl-tel-input/issues/365
.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  display: flex !important;
  flex-direction: column;
  flex-wrap: wrap;
}

.custom-select-flag {
  height: 100%;
  text-align: center;
  display: flex !important;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  padding-left: 0px !important;
  width: 85%;
  margin-left: -6px;
}

.selected-flag {
  div:nth-of-type(2n) {
    @extend .custom-select-flag;
  }
  outline: none;
  transition: none;
}

.selected-dial-code {
  @extend .custom-select-flag;
}

.arrow.down,
.arrow.up {
  height: 100%;
  border-right: 1px solid $inputBorder;
  display: flex;
  flex-wrap: wrap;
  padding-right: 5px;

  &::after {
    align-self: center;
  }
}
